import { defineAsyncComponent } from 'vue';

export default ({
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '50%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					owner: {
						config: {
							visible: false
						}
					},
					name: {},
				}
			}
		},
		select: {
			panel: {
				width: '70%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {}
				}
			}
		}
	}
})