import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TimeOut = _resolveComponent("TimeOut")!
  const _component_Modals = _resolveComponent("Modals")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["lds-container", { showloader: $setup.load }])
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "lds-dual-ring" }, null, -1)
    ]), 2),
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.layout), null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 1
        })
      ]),
      _: 1
    })),
    ($setup.timeout)
      ? (_openBlock(), _createBlock(_component_TimeOut, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Modals),
    _createVNode(_component_ContextMenu)
  ], 64))
}